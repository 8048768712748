import config from './config/environment';
import ENV from 'appkit/config/environment';
import EmberRouterScroll from 'ember-router-scroll';
import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';

@classic
export default class Router extends EmberRouterScroll {
  enableLogging = true;
  location = config.locationType;
  rootURL = config.rootURL;
  @service currentUser;
  @service hog;

  init() {
    super.init(...arguments);

    this.on('routeDidChange', _transition => {
      if (ENV.environment === 'test') {
        return;
      }

      window.dataLayer.push({
        event: 'pageview',
        userId: this.currentUser.userId,
      });

      this.hog.pageView(this.currentRoute?.queryParams);

      if (ENV.environment !== 'production') {
        return;
      }

      if (window._hsq) {
        window._hsq.push(['setPath', this.url]);
        window._hsq.push(['trackPageView']);
      }
    });
  }
}

Router.map(function () {
  this.route('private-mode');
  this.route('accounts', function () {
    this.route('default');
  });

  this.route('demo', { path: '/demo/:id' }, function () {
    this.route('calendar', { path: '/' });
    this.route('chart');
    this.route('market-data');
    this.route('nearby', function () {
      this.route('airbnb');
      this.route('homeaway');
      this.route('booking');
    });
    this.route('stats');
    this.route('customize');
  });

  this.route('sign-up');
  this.route('create-account', function () {
    this.route('pricing', function () {
      this.route('index', { path: '/' });
      this.route('skip-trial', { path: '/:skip_trial' });
    });
    this.route('insights', function () {
      this.route('index', { path: '/' });
      this.route('skip-trial', { path: '/:skip_trial' });
    });
  });

  this.route('login');
  this.route('logout');

  this.route('forgot');
  this.route('sudo');
  this.route('components');
  this.route('pricing', function () {
    //this.route('individuals', {path: '/'});
    this.route('property-managers');
    this.route('individuals');
  });

  this.route('search', function () {
    this.route('search-listing', { path: '/:id' });
  });

  // This is dumb. Do engines solve this? Could have a listing resource, but
  // the routes for preview and dashboard listings are different.
  this.route('preview', { path: 'preview/:id' }, function () {
    this.route('calendar', { path: '/' });
    this.route('chart');
    this.route('market-data');
    this.route('nearby', function () {
      this.route('airbnb');
      this.route('homeaway');
      this.route('booking');
    });
    this.route('stats');
  });

  // Redirect to dashboard/user
  this.route('user-redirect', { path: '/user' }, function () {
    this.route('index', { path: '/' });
    this.route('subpage', { path: '/:id' });
  });

  this.route('market-map-locator');

  this.route('dashboard', function () {
    this.route('parked');
    this.route('index', { path: '/' });
    this.route('grid', function () {
      this.route('filter');
      this.route('bulk');
      this.route('bulk-edit');
    });

    this.route('onboarding', function () {
      this.route('intro');
    });

    this.route('connect', function () {
      this.route('index', { path: '/' });
      this.route('channel', { path: '/channel/:channel_name' });
      this.route('assisted', { path: '/assisted/:channel_name' });
    });

    this.route('connect-prompt', function () {
      this.route('index', { path: '/' });
      this.route('channel', { path: '/channel/:channel_name' });
      this.route('assisted', { path: '/assisted/:channel_name' });
      this.route('populate-account', { path: '/populate-account/:id' });
      this.route('processing');
    });

    this.route('populate', function () {
      this.route('account', { path: '/:id' });
    });

    this.route('pricing', function () {
      this.route('join');
      this.route('map');
      this.route('booking-review');
      this.route('grid');
      this.route('index', { path: '/' }, function () {
        this.route('filter');
        this.route('bulk-edit');
        // for now, no separations for each bulk edit data
        // this.route('bulk-edit', function() {
        //   this.route('min-stays');
        // });
        this.route('booking-review', function () {
          this.route('index', { path: '/:id' }, function () {
            this.route('rates');
            this.route('listing-stats');
          });
        });
        this.route('setup', function () {
          this.route('index', { path: '/:id' });
        });
        this.route('bulk-suggestion');
      });

      this.route('listing', { path: '/:id' }, function () {
        this.route('calendar', { path: '/' }, function () {
          this.route('legacy');
        });
        this.route('chart');
        this.route('market-data');
        this.route('nearby', function () {
          this.route('airbnb');
          this.route('homeaway');
          this.route('booking');
        });
        this.route('stats');
        this.route('customize', function () {
          this.route('legacy');
        });
        this.route('base-price-helper');
      });
    });

    this.route('relay', function () {
      this.route('index', { path: '/' }, function () {
        this.route('reservations');
        this.route('merged-listings');
        this.route('unmerged-listings');
      });
      this.route('welcome');
      this.route('form', { path: '/register-interest' });
      this.route('connect', function () {
        this.route('index', { path: '/' });
        this.route('channel', { path: '/channel/:channel_name' });
      });
      this.route(
        'merged-listing-detail',
        { path: 'listing/:master_listing_id' },
        function () {
          this.route('index', { path: '/' });
          this.route('listing-settings', { path: '/settings' });
          this.route('listing-reservations', { path: '/reservations' });
          this.route('listing-availability', { path: '/staff/availability' });
        }
      );
      this.route('relay-settings', { path: '/settings' }, function () {
        this.route('index', { path: '/' });
      });
      this.route('v2', function () {
        this.route('index', { path: '/' });
      });
    });

    this.route('reservations', function () {
      this.route('index', { path: '/' });
      this.route('show', { path: '/:id' });
    });

    this.route('insights', function () {
      this.route('by-listing');
      this.route('stats', function () {
        this.route('index', { path: '/' });
      });
      this.route('comp-sets', function () {
        this.route('listings');
        this.route('sets', function () {
          this.route('index', { path: '/' });
          this.route('show', { path: '/:id' });
        });
      });
      this.route('owner-dashboard', function () {
        this.route('index', { path: '/' });
      });
      this.route('join');
      this.route('connect', function () {
        this.route('index', { path: '/' });
        this.route('channel', { path: '/channel/:channel_name' });
        this.route('assisted', { path: '/assisted/:channel_name' });
      });
    });

    this.route('user', function () {
      this.route('summary');
      this.route('billing', function () {
        this.route('card', function () {
          this.route('session');
          this.route('callback');
        });
        this.route('payment', function () {
          this.route('confirm');
        });
      });
      this.route('statements');
      this.route('accounts');
      this.route('notifications');
      this.route('tokens');
      this.route('credentials');
      this.route('accessibility');

      this.route('account', function () {
        this.route('refresh', { path: '/:id/refresh' });
      });
      this.route('payment', { path: '/payments/:id' });
    });

    // Redirect /dashboard/:id to dashboard/pricing/:id
    this.route('listing-redirect', { path: '/:id' }, function () {
      this.route('index', { path: '/' });
      this.route('subpage', { path: '/:id' });
    });

    this.route('owner-resource', function () {
      this.route('index', { path: '/' });
      this.route('projection', function () {
        this.route('index', { path: '/' });
        this.route('reference-listing');
        this.route('build-projection', function () {
          this.route('view', { path: '/:id' });
        });
      });
    });

    this.route('signal', function () {
      this.route('index', { path: '/' });
      this.route('booking-window', { path: '/booking_window' });
      this.route('amenities');
      this.route('other');
      this.route('ip-filters', { path: '/ip_filters' });
      this.route('welcome');
      this.route('form', { path: '/register-interest' });
    });
  });

  this.route('error.page-not-found', { path: '*:' });
  this.route('error.unavailable', { path: '/unavailable/:id' });
  this.route('error.server', { path: '/error' });

  this.route('data', function () {
    this.route('bulk-update');
    this.route('booking-report');
    this.route('import-reservations');
    this.route('global', function () {
      this.route('regions');
    });
    this.route('cluster', { path: 'cluster/:cluster_id' }, function () {
      this.route('regions');
      this.route('airbnb-listings');
      this.route('homeaway-availability');
      this.route('homeaway-pricing');
      this.route('airbnb-availability');
      this.route('airbnb-pricing');
      this.route('beyond-availability');
      this.route('beyond-airbnb-availability');
      this.route('seasonality');
      this.route('experimental-pricing');
    });
    this.route('market', { path: 'market/:market_id' }, function () {
      this.route('regions');
      this.route('map');
      this.route('homeaway-pricing');
      this.route('airbnb-pricing');
      this.route('hotel-prices');
      this.route('hotel-map');
      this.route('cluster-prices');
      this.route('market-overall');
    });
  });

  this.route('internal-tools', function () {
    this.route('bulk-update');
    this.route('posted-prices');
    this.route('booking-report');
    this.route('import-reservations');
    this.route('pms-fees-uploader');
    this.route('invalid-listings');
    this.route('auto-booking-review');
    this.route('website-provider');
    this.route('activity-log');
    this.route('change-currency');
    this.route('manual-overrides');
    this.route('reset-pms-connection');
    this.route('min-price-recommendations');
  });
});
